/* App.css */
body {
  font-family: 'Roboto', sans-serif;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.App {
  position: relative;
  height: 100vh;
  width: 100%;
}

.leaflet-container {
  height: 100vh;
  width: 100%;
}

.title-overlay {
  position: absolute;
  bottom: 10px;
  left: 10px;
  background: rgba(255, 255, 255, 0.9);
  padding: 15px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  z-index: 1000;
}

.title-overlay h1 {
  margin: 0 0 10px 0;
  font-size: 1.5em;
}

.title-overlay p {
  margin: 0;
  font-size: 1em;
}

.download-button, .toggle-racecourse-button {
  background-color: #007BFF;
  color: white;
  border: 5px solid #007BFF; /* Add 5px border */
  padding: 5px 5px;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 10px;
  margin-right: 5px; /* Add margin */
  margin-bottom: 5px;
}

.download-button:hover, .toggle-racecourse-button:hover {
  background-color: #0056b3;
  border: 5px solid #0056b3; /* Change border color on hover */
}

.fixed-tooltip {
  font-size: 12px;
  color: black;
  text-align: center;
}
