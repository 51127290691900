/* FleetMenu.css */
.fleet-menu {
    position: absolute;
    top: 10px;
    left: 10px;
    background: white;
    padding: 15px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    z-index: 1000;
    max-width: 300px; /* Adjust width as needed */
  }
  
  .fleet-menu-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .fleet-menu h2 {
    margin: 0;
    font-size: 1.2em;
  }
  
  .fleet-menu p {
    margin: 0.5em 0;
  }
  
  .fleet-menu button {
    background-color: #007BFF;
    color: white;
    border: 5px solid #007BFF; /* Add 5px border */
    padding: 5px 10px;
    border-radius: 4px;
    cursor: pointer;
    margin: 5px 0; /* Add margin */
  }
  
  .fleet-menu button:hover {
    background-color: #0056b3;
    border: 5px solid #0056b3; /* Change border color on hover */
  }
  
  .fleet-menu-select-container {
    position: relative;
  }
  
  .react-select-container {
    width: 100%;
  }
  
  .react-select__menu {
    z-index: 2000; /* Ensure the dropdown menu appears above other elements */
  }
  